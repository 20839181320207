
























































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { GET_ALL_FAQ } from '@/store/user/actions.type'
import { FAQDto } from '@/store/user/types'
import SearchResult from '@/components/search-results/SearchResult.vue'
import ResultsPage from '@/components/search-results/ResultsPage.vue'

@Component({
  components: { SearchResult, ResultsPage }
})
export default class FAQ extends Vue {
  @Action(GET_ALL_FAQ)
  public getAllFAQ: (locale: string) => Promise<Array<FAQDto>>

  faqDto: Array<FAQDto> = []

  loadFAQ (): void {
    this.getAllFAQ(this.$root.$i18n.locale).then((data: Array<FAQDto>) => {
      this.faqDto = data.map((faq: FAQDto) => {
        return {
          ...faq,
          question: faq.question.toString(),
          answer: faq.answer.toString(),
          value: faq.faq,
          visible: faq.answer.length < 133
        }
      })
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('faq.' + error.message), true)
    })
  }

  @Watch('locale')
  onValueChange (): void {
    this.loadFAQ()
  }

  get locale (): string {
    return this.$i18n.locale
  }

  mounted (): void {
    this.loadFAQ()
  }
}

